import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import * as Sentry from "@sentry/angular";
import { ParsedPath } from "parse-path";
import * as parsePath from "parse-path";

@Injectable({
  providedIn: "root",
})
export class SentryMonitoring {
  private static environmentName: string;

  private static targetName: string;

  public static init(user: any): void {
    let targetOrigin: string = localStorage.getItem("targetOrigin");
    this.environmentName = this.getEnvironmentName(targetOrigin);
    this.targetName = this.buildGatewayHostName(targetOrigin);

    if (!Sentry.isInitialized()) {
      Sentry.init({
        dsn: environment.sentryDsn,
        environment: this.environmentName,
        attachStacktrace: true,
        enabled: true,
        enableTracing: true,
        sampleRate: environment.sentrySampleRate,
        autoSessionTracking: true,
        tracePropagationTargets: ["localhost", this.targetName],
        tracesSampleRate: environment.sentrySampleRate,
        integrations: (integrations) => {
          if (
            !user ||
            !environment.replayUserBlacklist.includes(user.user_email)
          ) {
            integrations.push(
              Sentry.browserTracingIntegration({
                traceXHR: true,
                enableHTTPTimings: true,
                instrumentNavigation: true,
                instrumentPageLoad: true,
              }),
            );
            return integrations;
          }
        },
      });
    }

    if (user) {
      if (this.isNotessaUI(targetOrigin)) {
        this.setupNotessaUser(user);
      } else {
        this.setupFwiUser(user);
      }
    }
  }

  static readonly isNotessaUI = (path: string): boolean => {
    return (
      path.indexOf("newsweaver.com") > -1 ||
      path.indexOf("nw.com.staging.lan") > -1 ||
      path.indexOf("poppulo-app.com") > -1
    );
  };

  private static setupNotessaUser(user) {
    if (user) {
      Sentry.setUser({
        id: user.user_id,
        email: user.user_email,
        username: user.user_name,
      });

      Sentry.setTag("account_name", user.account_name);
      Sentry.setTag("enterprise_account", user.account_enterprise);
      Sentry.setTag("segment", user.account_id);
    }
  }

  private static setupFwiUser(user) {
    if (user) {
      Sentry.setUser({
        id: user.user_id,
        email: user.user_email,
        username: user.user_email,
      });

      Sentry.setTag("company_name", user.company_name);
      Sentry.setTag("company_id", user.company_id);
    }
  }

  static getEnvironmentName(path: string): string {
    if (path.indexOf("admin.newsweaver.com") !== -1) {
      return "london";
    } else if (path.indexOf("admin.us.newsweaver.com") !== -1) {
      return "chicago";
    } else if (path.indexOf("admin.nw.com.staging.lan") !== -1) {
      return "staging";
    } else if (path.indexOf("admin.eu") !== -1) {
      return "london";
    } else if (path.indexOf("admin.us") !== -1) {
      return "chicago";
    } else if (path.indexOf("staging") !== -1) {
      return "staging";
    } else if (path.indexOf("epistola") !== -1) {
      return "epistola";
    } else if (path.indexOf("staging.fwi-dev") !== -1) {
      return "staging";
    } else if (path.indexOf("eu1.fwicloud") !== -1) {
      return "london";
    } else if (path.indexOf("ap1.fwicloud") !== -1) {
      return "london";
    } else if (path.indexOf("fwicloud") !== -1) {
      return "chicago";
    } else {
      return "dev";
    }
  }

  public static buildGatewayHostName(path: string): string {
    const parsedPath: ParsedPath = parsePath(path);

    const pathname: string = parsedPath.pathname;
    const hostname: string = parsedPath.host;

    if (
      pathname !== undefined &&
      pathname.indexOf("notessa") > -1 &&
      hostname.indexOf("admin") > -1 &&
      (hostname.indexOf("newsweaver.com") > -1 ||
        hostname.indexOf("poppulo-app.com") > -1)
    ) {
      return hostname.replace("admin", "looker");
    } else if (hostname.indexOf("fwi-dev.com") > -1) {
      return "embedded-reports.poppulo-app.com.staging.lan";
    } else if (
      hostname.indexOf("eu1.fwicloud.com") > -1 ||
      hostname.indexOf("ap1.fwicloud.com") > -1
    ) {
      return "embedded-reports.eu.poppulo-app.com";
    } else if (hostname.indexOf("fwicloud.com") > -1) {
      return "embedded-reports.us.poppulo-app.com";
    } else {
      return hostname.replace("rep-looker-front-ui", "looker");
    }
  }
}
